<template>
  <!--  展现专业分类信息-->
  <div class="category">
    <el-form :inline="true" label-position="left">
      <el-form-item label="选择方向">
        <el-select v-model="type" placeholder="选择方向" @change="changeZero()">
          <el-option label="学硕" :value="1"></el-option>
          <el-option label="专硕" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form :inline="true">
      <el-form-item label="第一大类">
        <el-select v-model="nowFirst" placeholder="第一大类" @change="changeFirst" size="medium">
          <el-option
              v-for="item in first"
              :key="item.code"
              :label="getLabelTwo(item)"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="一级方向">
        <el-select v-model="nowTwo" placeholder="一级方向" @change="changeTwo">
          <el-option
              v-for="item in two"
              :key="item.code"
              :label="getLabelTwo(item)"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="二级方向">
        <el-select v-model="nowThird" placeholder="二级方向" @change="Institute(1)">
          <el-option
              v-for="item in third"
              :key="item.code"
              :label="getLabel(item)"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-form  :inline="true">
      <el-form-item label="请选择省" >
        <el-select v-model="nowProvince" placeholder="请选择省" size="medium" @change="Institute(1)">
          <el-option label="全部" value=""></el-option>
          <el-option
              v-for="item in province"
              :key="item.id"
              :label="item.disName"
              :value="item.disName">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="请选择学校等级">
        <el-select v-model="one" placeholder="请选择学校等级" size="medium" @change="Institute(1)">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="985" :value="9"></el-option>
          <el-option label="211" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-row class="item">
      <el-tag><h1>学校数量{{ school.total }}</h1></el-tag>
    </el-row>
    <el-table
        :data="school.records"
        @row-click="click"
        style="width: 100%">
      <el-table-column
          prop="schoolName"
          label="学校名称"
          width="180">
      </el-table-column>
      <el-table-column
          prop="schoolProviceid"
          label="学校位置"
          width="180">
      </el-table-column>
      <el-table-column
          label="是985？">
        <template slot-scope="props">
          {{ getTrue(props.row.schoolNine) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="schoolTwo"
          label="是211？">
        <template slot-scope="props">
          {{ getTrue(props.row.schoolTwo) }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        background
        :current-page="school.current"
        :page-size="school.size"
        @current-change="Institute"
        layout="prev, pager, next"
        :total="school.total"
    >
    </el-pagination>

    <el-dialog :title="nowSchool.schoolName" :visible.sync="dialogVisible">
      <el-table
          :data="institute">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
                border
                class="range"
                :data="props.row.rangeList"
                style="width: 100%">
              <el-table-column
                  prop="one"
                  label="政治"
                  width="180">
              </el-table-column>
              <el-table-column
                  prop="two"
                  label="外语"
                  width="180">
              </el-table-column>
              <el-table-column
                  prop="three"
                  label="业务课一">
              </el-table-column>
              <el-table-column
                  prop="four"
                  label="业务课二">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column
            prop="institute.name"
            label="学校名称"
            width="180">
        </el-table-column>
        <el-table-column
            prop="institute.departmentName"
            label="院部">
        </el-table-column>
        <el-table-column
            prop="institute.researchName"
            label="研究方向">
        </el-table-column>
        <el-table-column
            prop="institute.learningStyle"
            label="学习方式">
        </el-table-column>
        <el-table-column
            prop="institute.number"
            label="招收人数">
        </el-table-column>
        <el-table-column
            prop="institute.examStyle"
            label="考试方式">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {getArea, getCategory, getInstitute, getSchool,getInformationByCode} from "@/api/course"
export default {
  name: "Category",
  data() {
    return {
      dialogVisible:false,
      code:"",
      first: [],
      two: [],
      third: [],
      nowFirst: "",
      nowTwo: "",
      nowThird: "",
      type:1,
      school:[],
      nowProvince:"",
      province:"",
      one: 0,
      level: "全部",
      nowSchool:"",
      institute:"",
      // 选择的方向1 表示学硕，2 表示专硕
    }
  },
  mounted() {
   this.changeZero();
    this.Area();
  },
  methods: {
    click(row, column, event) {
      this.nowSchool = row;
      if(this.nowThird===undefined||this.nowThird===""){
        this.$message({
          type:"error",
          message:"请先选择专业",
          duration:2*1000
        })
        return
      }
      getInformationByCode(row.schoolCode,this.nowThird).then(result=>{
        this.institute=result.data.institute;
        this.dialogVisible = true;
      }).catch(error=>{
        this.$message({
          type:"error",
          duration:2*1000,
          message:error.message
        })
      })
    },
    /**
     * 获取专业信息
     */
    changeZero(){
      // 默认是选学硕
      getCategory("0", this.type).then(result => {
        this.first = result.data.category;
        getCategory(this.first[0].code, this.type).then(result => {
          this.two = result.data.category;
          getCategory(this.two[0].code, this.type).then(result => {
            this.third = result.data.category;
          })
        })
      })
    },
    // 获取第二级别
    getLabelTwo(item) {
      return "(" + item.code.substring(2) + ")" + item.name;
    },
    // 获取第一大类
    getLabel(item) {
      return "(" + item.code + ")" + item.name;
    },
    // 目录
    changeFirst() {
      getCategory(this.nowFirst, this.type).then(result => {
        this.two = result.data.category;
        getCategory(this.nowTwo, this.type).then(result => {
          this.third = result.data.category;
        })
      })
    },
    changeTwo() {
      getCategory(this.nowTwo, this.type).then(result => {
        this.third = result.data.category;
      })
    },

    Institute(Number) {
      console.log("选择了"+this.nowThird)
      if(this.nowThird===undefined||this.nowThird===""){
        this.$message({
          type:"error",
          message:"请先选择专业",
          duration:2*1000
        })
        return
      }
      getInstitute(this.nowThird, Number,this.nowProvince, this.one).then(result => {
        this.school = result.data.institute;
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        });
      })
    },
    Area() {
      getArea("402881ea3f5b1d14013f5b1fdc080006").then(result => {
        this.province = result.data.area;
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        })
      })
    },
    getTrue(number) {
      return number === 1 ? "是" : "否"
    }
  }
}
</script>

<style scoped >
.category{
  width: 100%;
}
.text{
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: right;
}
.item{
  margin: 20px;
}
.range{
  height: 100%;
  width: 100%;
  border: 1px solid #409EFF;
}
</style>
